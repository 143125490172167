import AuthProvider from './AuthProvider';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import Authenticated from './Authenticated';
import ShoppingListContainer from './ShoppingListContainer';
import MainListRedirect from './MainListRedirect';
import ShoppingListProvider from './ShoppingListProvider';
import FirebaseProvider from './FirebaseProvider';
import Layout from './Layout';
import ShoppingList from './ShoppingList';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import UsersProvider from './UsersProvider';
import { ErrorBoundary } from 'react-error-boundary';
import GlobalError from './GlobalError';
import CreateShoppingList from './CreateShoppingList';

const queryClient = new QueryClient();

function App() {
  const routes = createRoutesFromElements(
    <>
      <Route path="login" lazy={() => import('./Login')} />
      <Route path="register" lazy={() => import('./Register')} />
      <Route path="verify-email" lazy={() => import('./VerifyEmail')} />
      <Route path="reset-password" lazy={() => import('./ResetPassword')} />
      <Route
        path="/"
        element={
          <Authenticated>
            <UsersProvider>
              <Layout />
            </UsersProvider>
          </Authenticated>
        }
      >
        <Route index element={<Navigate to="lists" />} />
        <Route path="lists">
          <Route index element={<MainListRedirect />} />
          <Route path="new" element={<CreateShoppingList />} />
          <Route
            path=":listId"
            element={
              <ShoppingListProvider>
                <ShoppingListContainer />
              </ShoppingListProvider>
            }
          >
            <Route index element={<ShoppingList />} />
            <Route path="rename" lazy={() => import('./ShoppingListRename')} />
            <Route path="share" lazy={() => import('./ShoppingListShare')} />
            <Route path="*" element={<Navigate to=".." />} />
          </Route>
        </Route>
        <Route path="user" lazy={() => import('./UserProfilePage')} />
        <Route path="*" element={<Navigate to="lists" />} />
      </Route>
    </>
  );

  const router = createBrowserRouter(routes);

  return (
    <ErrorBoundary FallbackComponent={GlobalError}>
      <QueryClientProvider client={queryClient}>
        <FirebaseProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </FirebaseProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
